const phoneErrorCodes = {
    "000": "Valid Query",
    "001": "Invalid Query Format",
    "002": "Query Service Invalid",
    "003": "Country Not Allowed",
    "004": "Country Code Invalid",
    "005": "Wrong Number Length or Format",
    "006": "Range Not Allocated",
    "007": "Number Not Found - Onboard",
    "008": "Number Not Found - Remote",
    "009": "Service Error",
    "010": "Remote Timeout",
    "100": "Subscriber Error (Temporary)",
    "101": "Subscriber Error",
    "109": "Destination Network Error",
    "112": "No network response",
    "113": "Destination Error",
    "114": "Destination Network Temporary Unavailable",
    "200": "Remote Query Failed - GNR response provided",
    "V:1": "Fallback Error: Invalid Number",
    "V:2": "Fallback Error: Not allowed country",
  };

  export default phoneErrorCodes;