export const useAuthFetch = () => {

  const authFetch = async (url: string, options: any = {}) => {

    try {
      const { data, pending, error, refresh } = await useCsrfFetch(`/api/fetch`, {
        method: "post",
        body: {
          url,
          options,
        },
      });

      console.log("$csrfFetch:data", { data, pending, error, refresh });

      if (error.value) {
        throw new Error(error.value.data.message, { cause: error });
      }
      return data.value;
    } catch (error) {
      console.error(error);
      console.log(error);
      throw error;
    }

  };

  return authFetch;
};
