export const useCheckEmail = () => {
  const isEmailPatternBlocked = async (email: string) => {
    const blockPatterns = [".co.site", "co.site>"];

    if (blockPatterns.some((pattern) => email.includes(pattern))) return true;

    return false;
  };
  const isEmailDomainBlocked = async (email: string) => {
    const emailDomain: any = email.split("@").pop();

    const allowedDomains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com"];

    if (allowedDomains.includes(emailDomain)) return false;

    return false;
  };
  const isEmailValid = async (email: string, options: object) => {
    const token = btoa(JSON.stringify(options));

    return await useCsrfFetch("/.netlify/functions/check-email", {
      method: "post",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
      body: `email=${encodeURIComponent(email)}&token=${token}`,
    });
  };

  return { isEmailPatternBlocked, isEmailDomainBlocked, isEmailValid };
};
