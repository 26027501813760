export const useCheckAccount = () => {
  const isAccountValid = async (email: string, phone: string) => {
    const config = useRuntimeConfig().public;

    const { data: isAccountValidResponse } = await useFetch(`${config.REGISTER_TRANSMIT_API}/validate-registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `email=${encodeURIComponent(email)}&phone=${encodeURIComponent(phone)}`,
    });

    return isAccountValidResponse.value;
  };

  return { isAccountValid };
};
